module.exports = {
  siteTitle: 'Lei Ma | Applied Scientist',
  siteDescription:
    'Lei Ma is an applied scientist based in Germany. Lei Ma is also a physicist and science fiction creator.',
  siteKeywords:
    'Lei Ma, Physicist, Data Scientist, Data Engineer, Applied Scientist, Machine Learning',
  siteUrl: 'https://leima.me',
  siteLanguage: 'en_US',

  googleVerification: '',

  name: 'Lei Ma',
  location: 'Germany',
  email: 'hi@leima.is',
  github: 'https://github.com/emptymalei',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/emptymalei',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/leima137',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experiences',
      url: '#jobs',
    },
    {
      name: 'Projects',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@emptymalei',
  googleAnalyticsID: 'UA-49034078-14',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
